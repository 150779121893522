import React from 'react';
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom";
import NavBarLink from './NavBarLink';
import { ChevronDown } from 'react-bootstrap-icons';

const NavBarLink2 = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    {/* Mobile */}
    <li className="nav-item right-menu pt-1 d-block d-lg-none">
          <div class="btn-group dropdown">
          <span className={splitLocation[1] === `${props.Link_To}` ? `dropdown-toggle nav-link hover-pri fw-500 pe-lg-3 pe-xl-4 text-ss-primary ${props.Border}` : `dropdown-toggle nav-link hover-pri fw-500 pe-lg-3 pe-xl-4 ${props.Border}`} to={`/${props.Link_To}`}> {props.Name} </span>
          <Link to="" className="btn px-4 dropdown-toggle dropdown-toggle-split border-0" data-bs-toggle="dropdown-menu" aria-expanded="false">
          <ChevronDown className='text-dark fw-600'/>
              <span className="visually-hidden">Toggle Dropdown</span>
              </Link>
          </div>
          <ul className="dropdown-menu hover1 fs-ss-links w-content">
            {props.DropdownMenu}
          </ul>
    </li>
    
    {/* Desktop */}
    <li className="nav-item right-menu pt-1 d-none d-lg-block">
          <span className={splitLocation[1] === `${props.Link_To}` ? `dropdown-toggle nav-link hover-pri fw-500 pe-lg-3 pe-xl-4 text-ss-primary ${props.Border}` : `dropdown-toggle nav-link hover-pri fw-500 pe-lg-3 pe-xl-4 ${props.Border}`} to={`/${props.Link_To}`}> {props.Name} </span>
          <ul className="dropdown-menu hover1 w-content mt-1 rounded-3">
            {props.DropdownMenu}
          </ul>
    </li>

    </>
  )
}

export default NavBarLink2