import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "../src/assets/css/style.css";
import "./portal/assets/css/portal.css";
import 'react-tabs/style/react-tabs.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from "react-hot-toast";
import { useUserContext } from "./context/UserContext";
import LandingPage from "./pages/LandingPage";
// import PrivateRoute from "./routes/PrivateRoute";
// Pages
const Home = React.lazy(() => import("./pages/Home"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const FAQs = React.lazy(() => import("./pages/FAQs"));
const ConvoScribe = React.lazy(() => import("./pages/ConvoScribe"));
const VirtualScribe = React.lazy(() => import("./pages/VirtualScribe"));
const Contact = React.lazy(() => import("./pages/Contact"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const Blog = React.lazy(() => import("./pages/Blog"));
const BookDemo = React.lazy(() => import("./pages/BookDemo"));
const AppPrivacyPolicy = React.lazy(() => import("./pages/AppPrivacyPolicy"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndConditions")
);
const PaymentSuccess = React.lazy(() => import("./pages/PaymentSuccess"));
const Loading = React.lazy(() => import("./portal/components/Loading"));
const Pricing = React.lazy(() => import("./pages/Pricing"));
const Sandbox4 = React.lazy(() => import("./pages/Sandbox4"));
const Sandbox3 = React.lazy(() => import("./pages/Sandbox3"));

const portalUrl = process.env.REACT_APP_PORTAL_URL;
const ExternalRedirect = ({ url }) => {
    React.useEffect(() => {
      window.location.href = url;
    }, [url]);
  
    return null; // This component does not render anything
  };

//dashboard
// const Dashboard = React.lazy(() => import("./portal/pages/Home"));
// const SignUp = React.lazy(() => import("./pages/SignUp"));
// const HomePage = React.lazy(() => import("./portal/pages/HomePage"));
// const DiagnoseDictationRecording = React.lazy(() =>
//   import("./portal/pages/DiagnoseDictationRecording")
// );
// const DiagnoseDictationText = React.lazy(() =>
//   import("./portal/pages/DiagnoseDictationText")
// );
// const DiagnoseDictationUpload = React.lazy(() =>
//   import("./portal/pages/DiagnoseDictationUpload")
// );
// const DiagnoseDictationUploadConversion = React.lazy(() =>
//   import("./portal/pages/DiagnoseDictationUploadConversion")
// );
// const DiagnoseDictationConversionRecording = React.lazy(() =>
//   import("./portal/pages/DiagnoseDictationConversationRecording")
// );
// const ScribeTheConversation = React.lazy(() => import("./portal/pages/v2-ScribeTheConversation"));
// const Dictate = React.lazy(() => import("./portal/pages/v2-Dictate"));
// const ImportConversation = React.lazy(() => import("./portal/pages/v2-ImportConversation"));
// const ImportDictation = React.lazy(() => import("./portal/pages/v2-ImportDictation"));

// const Settings = React.lazy(() =>
//   import("./portal/pages/Settings")
// );
// const Templates = React.lazy(() =>
//     import("./portal/pages/Templates")
// );
// const Login = React.lazy(() => import("./portal/pages/Login"));
// const ChangePassword = React.lazy(() =>
//   import("./portal/pages/ChangePassword")
// );
// const VerifyAccount = React.lazy(() => import("./pages/VerifyAccount"));
// const History = React.lazy(() => import("./portal/pages/History"));
// const HomeRecording = React.lazy(() => import("./portal/pages/HomeRecording"));
// const CurrentSubscription = React.lazy(() =>
//     import("./portal/pages/CurrentSubscription")
// );
const Error = React.lazy(() => import("./portal/pages/Error"));

const App = () => {
    const { userAuth, userData, isLoading } = useUserContext();

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
        <Suspense fallback={<Loading />}>
            <Toaster position="top-right" reverseOrder={false} />
            <Routes>
                <Route
                    path="/"
                    element={<Home userAuth={userAuth} userData={userData} />}
                />        
                <Route
                    path="/about-us"
                    element={<AboutUs userAuth={userAuth} userData={userData} />}
                />              
                <Route
                    path="/faqs"
                    element={<FAQs userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/contact-us"
                    element={<Contact userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/convo-scribe"
                    element={<ConvoScribe userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/virtual-scribe"
                    element={<VirtualScribe userAuth={userAuth} userData={userData} />}
                />    
                
                <Route
                    path="/ai-scribe"
                    element={<LandingPage userAuth={userAuth} userData={userData} />}
                />          

                <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicy userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/app-privacy-policy"
                    element={<AppPrivacyPolicy userAuth={userAuth} userData={userData} />}
                />          
                <Route
                    path="/terms-and-conditions"
                    element={
                    <TermsAndConditions userAuth={userAuth} userData={userData} />
                    }
                />
                <Route
                    path="/payment-success"
                    element={<PaymentSuccess userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/plans-pricing"
                    element={<Pricing userAuth={userAuth} userData={userData} />}
                />

                {/* dashboard */}
                
                <Route
                    path="/blogs"
                    element={<Blogs userAuth={userAuth} userData={userData} />}
                /> 
                <Route
                    path="/book-demo"
                    element={<BookDemo userAuth={userAuth} userData={userData} />}
                />                   
                <Route
                    path="/blog/:slug"
                    element={<Blog userAuth={userAuth} userData={userData} />}
                />            
                <Route
                    path="/sandbox3"
                    element={<Sandbox3 userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/sandbox4"
                    element={<Sandbox4 userAuth={userAuth} userData={userData} />}
                />
                

                <Route
                    path="/account"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/home"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/diagnose-dictation-recording"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/diagnose-dictation-text"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/diagnose-dictation-audio"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/diagnose-dictation-upload-conversation"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/diagnose-dictation-conversion-recording"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/scribe-the-conversation"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/dictate"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/import-conversation"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/import-dictation"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/home-rec"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/history"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/current-subscription"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/settings"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/templates"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/login"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/sign-up"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/set-password/:user/:token"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route
                    path="/account/verify-account/:user/:token"
                    element={<ExternalRedirect url={portalUrl} />}
                />
                <Route path="/account/*" element={<ExternalRedirect url={portalUrl} />} />
                {/* 
                    <Route
                        path="/account/"
                        element={<Login userAuth={userAuth} userData={userData} />}
                    />
                    <Route
                    path="/account/home"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <HomePage userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />
                <Route
                    path="/account/diagnose-dictation-recording"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <DiagnoseDictationRecording userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />
                <Route
                    path="/account/diagnose-dictation-text"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <DiagnoseDictationText userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />
                <Route
                    path="/account/diagnose-dictation-audio"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <DiagnoseDictationUpload
                        userAuth={userAuth}
                        userData={userData}
                        />
                    </PrivateRoute>
                    }
                />
                <Route
                    path="/account/diagnose-dictation-upload-conversation"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <DiagnoseDictationUploadConversion
                        userAuth={userAuth}
                        userData={userData}
                        />
                    </PrivateRoute>
                    }
                />
                <Route
                    path="/account/diagnose-dictation-conversion-recording"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <DiagnoseDictationConversionRecording
                        userAuth={userAuth}
                        userData={userData}
                        />
                    </PrivateRoute>
                    }
                />

                <Route
                    path="/account/scribe-the-conversation"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <ScribeTheConversation userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />

                <Route
                    path="/account/dictate"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <Dictate userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />

                <Route
                    path="/account/import-conversation"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <ImportConversation userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />

                <Route
                    path="/account/import-dictation"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <ImportDictation userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />

                <Route
                    path="/account/home-rec"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <HomeRecording userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />
                <Route
                    path="/account/history"
                    element={
                    <PrivateRoute isSubscriptionRequired={true}>
                        <History userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />

                <Route
                    path="/account/current-subscription"
                    element={
                    <PrivateRoute>
                        <CurrentSubscription userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />
                
                <Route
                    path="/account/settings"
                    element={
                    <PrivateRoute>
                        <Settings userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />
                <Route
                    path="/account/templates"
                    element={
                    <PrivateRoute>
                        <Templates userAuth={userAuth} userData={userData} />
                    </PrivateRoute>
                    }
                />

                <Route path="/account/login" element={<Navigate to="/" />} />
                <Route
                    path="/account/sign-up"
                    element={<SignUp userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/account/set-password/:user/:token"
                    element={<ChangePassword userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/account/verify-account/:user/:token"
                    element={<VerifyAccount userAuth={userAuth} userData={userData} />}
                />
                <Route path="/account/*" element={<Error />} /> */}
                <Route path="*" element={<Error />} />
            </Routes>
        </Suspense>
        </>
    );
};

export default App;
